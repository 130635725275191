import React from "react";
import roadmapImg from "./images/roadmap.jpeg";

export const Roadmap = () => {
    return (
        <div>
            <div style={{ margin: "0 auto", display: "block" }}>
                <img src={roadmapImg} style={{ maxWidth: "1200px", margin: "0 auto", display: "block", marginBottom: "100px" }} />
            </div>
        </div>
    );
};
