import React, { StrictMode } from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { Footer } from "./Components/Footer";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
const path = window.location.pathname.replace("/", "");
const pathName = !path ? "index" : path;

root.render(
    <StrictMode>
        <div id={`page-${pathName}`}>
            <App />
            <Footer />
        </div>
    </StrictMode>
);
