import logo from "../images/logo.png";

export const VideoBanner = () => {
    return (
        <div id="demoreel">
            <div id="banner-overlay">
                <div className="banner-logo">
                    <img src={logo} />
                </div>
            </div>
            <iframe id="demo-reel" src="https://player.vimeo.com/video/735941550?h=4c47a1efab&background=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
    );
};
