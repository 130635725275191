import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVimeoV, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
    return (
        <div id="footer" className="mt-auto">
            <div className="icon-circle vimeo">
                <a href="https://vimeo.com/showcase/9790462" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faVimeoV} />
                </a>
            </div>
            <div className="icon-circle linkedin">
                <a href="https://www.linkedin.com/in/christopher-trudeau-93209774" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
            </div>
            <div className="icon-circle twitter">
                <a href="https://twitter.com/VideoIsSocial" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitter} />
                </a>
            </div>
        </div>
    );
};
