import { VideoBanner } from "./Components/VideoBanner";
import { ProjectGrid } from "./Components/ProjectGrid";
import { projectList } from "./data";
import { Col, Container, Row } from "react-bootstrap";
import FadeIn from "react-fade-in";
import chris from "./images/chrisTHUMBflatINSTA.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { Title } from "./Components/Title";

export const Home = () => {
    const renderFeaturedWork = () => {
        return (
            <section id="featured-work">
                <h1>
                    <Title text={"Featured Work"} />
                </h1>
                <FadeIn transitionDuration={2000}>
                    <ProjectGrid projectList={projectList.slice(0, 6)} />
                </FadeIn>
                <div className="button-wrapper">
                    <a href="/projects" className="btn btn-primary btn-lg" role="button" aria-pressed="true">
                        VIEW PROJECT PORTFOLIO
                    </a>
                </div>
            </section>
        );
    };

    const renderReference = () => {
        return (
            <section id="reference">
                <Container>
                    <div className="quotation">
                        &quot;Over the years Chris has emerged as a critical thinker and creative genius who with his strong sense of style and design brings a stylistic flair to every video content project we have worked together on.&quot;
                    </div>
                    <>
                        <div className="photo">
                            <img src="./images/bobwinsorREV.jpg" />
                        </div>
                        <div className="person">
                            <div className="name">Robert Winsor</div>
                            <div className="title">Senior VP, CBS Media Group</div>
                        </div>
                    </>
                </Container>
            </section>
        );
    };

    const renderClientLogos = () => {
        return (
            <section id="clients">
                <Container>
                    <Row>
                        <Col md="3">
                            <img src="./images/client-logos/CBS_logo_GREY.png" />
                        </Col>
                        <Col md="3">
                            <img src="./images/client-logos/absolut_logo_BLUE-768x355.png" />
                        </Col>
                        <Col md="3">
                            <img src="./images/client-logos/cbswatch_logo_GREY.png" />
                        </Col>
                        <Col md="3">
                            <img src="./images/client-logos/Reuters-Logo-768x186.png" />
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    };

    const renderServices = () => {
        return (
            <section id="services">
                <div id="services-overlay"></div>
                <Container>
                    <h3>Exponentially Grow Your YouTube Audience</h3>
                    <div className="image-wrapper">
                        <img src={chris} />
                    </div>
                    <div className="name">Christopher Trudeau</div>
                    <div className="title">Founder, Video Is Social</div>
                    <div className="button-wrapper">
                        <a href="https://www.upwork.com/fl/christophertrudeau3" className="btn btn-primary btn-lg" role="button" aria-pressed="true" target="_blank" rel="noreferrer">
                            PUT VIDEO IS SOCIAL TO WORK FOR YOU
                        </a>
                    </div>
                    <h3 className="divider">WHAT I CAN DO FOR YOU</h3>
                    <div>
                        <ul id="services-list">
                            <li>
                                <FontAwesomeIcon icon={faPlayCircle} />
                                <div className="service">YOUTUBE SEO & CHANNEL MANAGEMENT</div>
                                <div className={`service-description`}>
                                    Why are your YouTube subscribers and video views so low? What are the best practices to get the YouTube algorithm to suggest your video? Should you focus on YouTube or Instagram? Should your videos be vertical or
                                    horizontal? There’s a lot to know, and I have the expertise to solve your organizations social video problems.
                                </div>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faPlayCircle} />
                                <div className="service">SOCIAL VIDEO EDITING</div>
                                <div className={`service-description`}>
                                    Do you need your footage to stand out from the competition? Want to bring a professional high end post-production polish to your video content? Then you’ve come to the right place. I’ve edited over 600 social media
                                    videos for large companies for over 11 years and can give the same outstanding visuals to your brand.
                                </div>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faPlayCircle} />
                                <div className="service">PRE-PRODUCTION SOCIAL VIDEO CONSULTING</div>
                                <div className={`service-description`}>
                                    More and more people are able to get professional looking footage with merely an iPhone or DSLR camera all on their own and skipping expensive professional crews. I can help you avoid costly shooting mistakes by
                                    helping you develop a game plan before you start shooting. Guaranteeing that you get the footage you want, to make the video that you need.
                                </div>
                            </li>
                        </ul>
                        <div className="start-now">
                            <a href="https://www.upwork.com/o/profiles/users/_~01ee41aa4c4d9a0ebe/" target="_blank" rel="noreferrer">
                                START NOW
                            </a>
                        </div>
                    </div>
                </Container>
            </section>
        );
    };

    return (
        <>
            <VideoBanner />
            <div id="pagebody">
                {renderFeaturedWork()}
                {renderReference()}
                {renderClientLogos()}
            </div>
            {renderServices()}
        </>
    );
};
