export const Title = ({ text }: { text: string }) => {
    return (
        <div className="header-title">
            <div className="header-container">
                <div className="head-title head-title-2 text-left clearfix">
                    <h1 className="the-title">{text}</h1>
                    <div className="title-underline"></div>
                </div>
            </div>
        </div>
    );
};
