import { Project } from "../types";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    600: 1,
};

export const ProjectGrid = ({ projectList }: { projectList: Project[] }) => {
    const projects: any = [];
    projectList
        .filter((p: Project) => !!p.display)
        .forEach((p: Project) =>
            projects.push(
                <div className="project-item" key={p.id}>
                    <a href={`/projects/${p.slug}`}>
                        <figure className="imghvr-fade imghvr-blur">
                            <img loading="lazy" src={`./images/project-thumbnails/${p.thumbnail}`} alt={p.title} />
                            <figcaption>
                                <div className="caption-inside">
                                    <h3 className="ih-fade-right ih-delay-xs">{p.title}</h3>
                                    <h5 className="ih-fade-right ih-delay-xs">{p.clientName}</h5>
                                </div>
                            </figcaption>
                        </figure>
                    </a>
                </div>
            )
        );
    return (
        <Masonry breakpointCols={breakpointColumnsObj} className="project-masonry" columnClassName="project-masonry_column">
            {projects}
        </Masonry>
    );
};
