import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./Home";
import { Projects } from "./Projects";
import { Contact } from "./Contact";
import { SiteNav } from "./Components/Nav";
import { Roadmap } from "./Roadmap";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
    return (
        <>
            <SiteNav />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="projects/:slug" element={<Projects />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="roadmap" element={<Roadmap />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
