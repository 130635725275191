import { projectList } from "./data";
import { ProjectGrid } from "./Components/ProjectGrid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Project } from "./types";
import { Col, Container, Row } from "react-bootstrap";
import FadeIn from "react-fade-in";
import logo from "./images/logo.png";

export const Projects = () => {
    const projects = projectList;
    const [project, setProject] = useState<Project | undefined>(undefined);
    const params = useParams();

    useEffect(() => {
        if (params && params.slug) {
            setProject(projectList.find((p: Project) => p.slug === params.slug));
        }
    }, []);

    const renderLetsWork = () => {
        return (
            <section id="upwork">
                <FadeIn>
                    <div className="clearfix intro">So What&apos;s Next?</div>
                    <div className="upwork-links">
                        <a href="https://www.upwork.com/freelancers/christophertrudeau3" target="_blank" rel="noreferrer">
                            Are You Ready?&nbsp;
                        </a>
                        <a href="https://www.upwork.com/freelancers/christophertrudeau3" rel="noreferrer" className="lets-work">
                            Let’s Work!
                        </a>
                    </div>
                </FadeIn>
            </section>
        );
    };

    return (
        <div>
            {project ? (
                <Container className="project-record">
                    <Row className="justify-content-md-center">
                        <Col lg="8" className="project-embed">
                            <FadeIn transitionDuration={2000}>
                                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                                    <iframe
                                        style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                                        src={`${project.embed}?h=1731810038&title=0&byline=0&portrait=0`}
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen></iframe>
                                </div>
                            </FadeIn>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </Col>
                        <Col lg="4">
                            <div className="project-title clearfix">
                                <h1 className="clearfix">
                                    <span>{project.title}</span>
                                </h1>
                            </div>
                            <div className="project-description">{project.description}</div>
                            <Row className="project-meta">
                                <Col xs="12" sm="6" lg="6">
                                    <span className="clearfix">CLIENT NAME</span>
                                    {project.clientName}
                                </Col>
                                <Col xs="12" sm="6" lg="6">
                                    <span className="clearfix">DATE</span>
                                    {project.date}
                                </Col>
                            </Row>
                        </Col>
                        <div className="container">
                            <div className="project-back">
                                <a href="/projects">Back</a>
                            </div>
                        </div>
                    </Row>
                </Container>
            ) : (
                <>
                    <div className="projects-logo">
                        <a href="/">
                            <img src={logo} />
                        </a>
                    </div>
                    <div id="project-grid">
                        <FadeIn transitionDuration={800}>
                            <ProjectGrid projectList={projects} />
                        </FadeIn>
                    </div>
                    {renderLetsWork()}
                </>
            )}
        </div>
    );
};
