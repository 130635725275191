import { Title } from "./Components/Title";
import logo from "./images/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState } from "react";

export const Contact = () => {
    const captchaRef = useRef(null);
    // @ts-ignore
    const [token, setToken] = useState<string | undefined>(undefined);
    const siteKey = process.env.REACT_APP_CT_RECAPTCHA_KEY ? process.env.REACT_APP_CT_RECAPTCHA_KEY : "";
    const [name, setName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [subject, setSubject] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [thanks, setThanks] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    const onChange = (v: any) => {
        setToken(v);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // @ts-ignore
        setToken(captchaRef.current.getValue());
        // @ts-ignore
        captchaRef.current.reset();

        if (process.env.REACT_APP_API_URL) {
            setThanks("Checking captcha.");
            const recapVerify = await fetch(process.env.REACT_APP_API_URL, {
                method: "post",
                body: JSON.stringify({ token, name, email, subject, message }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (recapVerify && recapVerify.status === 200) {
                setThanks("Thank you.  We'll reach out to you soon.");
            } else {
                // @ts-ignore
                captchaRef.reset();
                setToken(undefined);
                setError("Invalid Captcha. Please try again.");
            }
        }
    };

    return (
        <div className="contact-form-wrapper">
            <div className="projects-logo">
                <a href="/">
                    <img src={logo} />
                </a>
            </div>
            <div className="contact-form">
                <Title text="Contact" />
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="nameInput">Name</label>
                        <input type="text" required className="form-control" id="nameInput" size={40} onChange={(v) => setName(v.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailInput">Email address</label>
                        <input type="email" required className="form-control" id="emailInput" size={40} onChange={(v) => setEmail(v.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="subjectInput">Subject</label>
                        <input type="text" className="form-control" id="subjectInput" size={40} onChange={(v) => setSubject(v.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="messageInput">Message</label>
                        <textarea required className="form-control" id="messageInput" rows={10} onChange={(v) => setMessage(v.target.value)}></textarea>
                    </div>
                    {error ? <div className="error">{error}</div> : <></>}
                    {thanks ? (
                        <div className="thanks">{thanks}</div>
                    ) : (
                        <>
                            <ReCAPTCHA sitekey={siteKey} ref={captchaRef} id="recaptcha" onChange={onChange} />
                            <input type="submit" value="Send" className="" disabled={!token || !email || !message} />
                        </>
                    )}
                </form>
            </div>
        </div>
    );
};
