import { Project } from "./types";

export const projectList: Project[] = [
    {
        id: 1,
        clientName: "CBS",
        date: "December, 2015",
        description: "Behind the Scenes of the 2015 Victoria's Secret Fashion Show.",
        display: true,
        embed: "https://player.vimeo.com/video/305823193",
        slug: "victorias-secret-fashion-show-2015-behind-the-scenes",
        thumbnail: "victoriassecret2015_logo-1400x800.png",
        title: "Victoria's Secret",
        type: "entertainment",
    },
    {
        id: 2,
        clientName: "CBS",
        date: "September, 2015",
        description: "Promotional feature for the series finale of one of the most popular shows in the history of television.",
        display: true,
        embed: "https://player.vimeo.com/video/254906155",
        slug: "csi-finale",
        thumbnail: "csifinale-1400x800.jpg",
        title: "CSI: Finale",
        type: "entertainment",
    },
    {
        id: 3,
        clientName: "CBS",
        date: "July, 2018",
        description: "Melissa Benoist discusses the importance of Supergirl as a role model for young girls.",
        display: true,
        embed: "https://player.vimeo.com/video/296504049",
        slug: "supergirls",
        thumbnail: "Supergirl_logo-1400x800.png",
        title: "Supergirls!",
        type: "entertainment",
    },
    {
        id: 4,
        clientName: "Absolut/CBS Watch!",
        date: "January, 2018",
        description: "Instructional video for making an Absolut Vodkarita. Part of Absolut Lime promotional campaign.",
        display: true,
        embed: "https://player.vimeo.com/video/257561138",
        slug: "absolutvodkarita",
        thumbnail: "absolutlime-1400x800.jpg",
        title: "Absolut Vodkarita",
        type: "commercial",
    },
    {
        id: 5,
        clientName: "CBS",
        date: "February, 2014",
        description: 'Promotional video for "The Night That Changed America: A Grammy Salute to The Beatles." Featuring interviews with Ringo Starr, Annie Lennox, Imagine Dragons, Maroon 5 and more.',
        display: true,
        embed: "https://player.vimeo.com/video/310456306",
        slug: "the-beatles-a-grammy-salute",
        thumbnail: "beatles_special-1400x800.jpg",
        title: "The Beatles: A Grammy Salute",
        type: "entertainment",
    },
    {
        id: 6,
        clientName: "CBS",
        date: "February, 2016",
        description: "The cast of The Big Bang Theory gives a behind the scenes look at the 200th episode.",
        display: true,
        embed: "https://player.vimeo.com/video/260104433",
        slug: "the-big-bang-theory",
        thumbnail: "BigBangTheory200th-1400x800.jpg",
        title: "Big Bang Theory",
        type: "entertainment",
    },
    {
        id: 7,
        clientName: "CBS",
        date: "March, 2014",
        description: "Feature that aired immediately following the death of one of the main characters of CBS's The Good Wife.",
        display: true,
        embed: "https://player.vimeo.com/video/91731394",
        slug: "good-wife",
        thumbnail: "the-good-wifeUSE-1400x800.jpg",
        title: "The Good Wife",
        type: "entertainment",
    },
    {
        id: 8,
        clientName: "CBS",
        date: "September, 2011",
        description: "Behind the scenes feature for the first season of Person of Interest.",
        display: true,
        embed: "https://player.vimeo.com/video/260290880",
        slug: "person-of-interest",
        thumbnail: "person-of-interestUSE-1400x800.jpg",
        title: "Person of Interest",
        type: "entertainment",
    },
    {
        id: 9,
        clientName: "CBS",
        date: "February, 2018",
        description: "A tour of the Celebrity Big Brother house, with host Julie Chen.",
        display: true,
        embed: "https://player.vimeo.com/video/255462310",
        slug: "celebrity-big-brother",
        thumbnail: "BigBrotherCeleb1-1400x800.jpg",
        title: "Big Brother",
        type: "entertainment",
    },
    {
        id: 10,
        clientName: "CBS",
        date: "November, 2017",
        description: "Young Sheldon social media feature on the new character Meemaw.",
        display: true,
        embed: "https://player.vimeo.com/video/255455569",
        slug: "young-sheldon-meemaw",
        thumbnail: "YoungSheldonfeat-1400x800.jpg",
        title: "Young Sheldon",
        type: "entertainment",
    },
    {
        id: 11,
        clientName: "CBS",
        date: "September, 2011",
        description: "Promotional feature for the series premiere of Survivor: South Pacific.",
        display: true,
        embed: "https://player.vimeo.com/video/310216386",
        slug: "survivor-23-preview",
        thumbnail: "Survivor_logo-1400x800.jpg",
        title: "Survivor 23: Preview",
        type: "entertainment",
    },
    {
        id: 12,
        clientName: "CBS",
        date: "November, 2015",
        description: "Promotional feature for the 5th season premiere of 2 Broke Girls.",
        display: true,
        embed: "https://player.vimeo.com/video/310218444",
        slug: "2-broke-girls",
        thumbnail: "2brokegirlsLOGO-1400x800.jpg",
        title: "2 Broke Girls",
        type: "entertainment",
    },
    {
        id: 13,
        clientName: "CBS",
        date: "June, 2013",
        description: "Introducing the ensemble cast of the event series Under the Dome.",
        display: true,
        embed: "https://player.vimeo.com/video/72199872",
        slug: "under-the-dome",
        thumbnail: "Under-The-Dome-Season-2-Logo-under-the-dome-37258296-1877-1052-1400x800.png",
        title: "Under the Dome",
        type: "entertainment",
    },
    {
        id: 14,
        clientName: "CBS",
        date: "September, 2016",
        description: "Promotional feature for the premiere of the third season of Madam Secretary.",
        display: true,
        embed: "https://player.vimeo.com/video/307080349",
        slug: "madam-secretary",
        thumbnail: "madamsecretaryLOGO-1400x800.jpg",
        title: "Madam Secretary",
        type: "entertainment",
    },
    {
        id: 15,
        clientName: "American Airlines/CBS",
        date: "August, 2013",
        description: 'Public Service Announcement for American Airlines "Miles For Kids In Need" Program.',
        display: true,
        embed: "https://player.vimeo.com/video/261886071",
        slug: "american-airlines-psa",
        thumbnail: "AAJimNantzPSA30sec-HDrev-1400x800.jpg",
        title: "AA PSA",
        type: "commercial",
    },
    {
        id: 16,
        clientName: "CBS",
        date: "May, 2016",
        description: "A behind the scenes look at the farewell episode for Michael Weatherly.",
        display: true,
        embed: "https://player.vimeo.com/video/307083977",
        slug: "ncis",
        thumbnail: "ncis_logo-1400x800.jpg",
        title: "NCIS",
        type: "entertainment",
    },
    {
        id: 17,
        clientName: "CBS",
        date: "October, 2016",
        description: "A look behind the scenes of the making of the 100th episode of Elementary.",
        display: true,
        embed: "https://player.vimeo.com/video/307095419",
        slug: "elementary",
        thumbnail: "elementaryLOGO-1400x800.jpg",
        title: "Elementary",
        type: "entertainment",
    },
    {
        id: 18,
        clientName: "CBS",
        date: "February, 2016",
        description: "Phil Keoghan takes us on a tour of one of the challenges of The Amazing Race.",
        display: true,
        embed: "https://player.vimeo.com/video/306914402",
        slug: "amazing-race",
        thumbnail: "AmazingRaceLOGO-1400x800.jpg",
        title: "Amazing Race",
        type: "entertainment",
    },
    {
        id: 19,
        clientName: "CBS",
        date: "April, 2016",
        description: "A behind the scenes look at series finale of Mike and Molly with the producers and stars of the show.",
        display: true,
        embed: "https://player.vimeo.com/video/307089418",
        slug: "mike-molly",
        thumbnail: "MIKE_MOLLY_logo_backplate-1400x800.jpg",
        title: "Mike & Molly",
        type: "entertainment",
    },
    {
        id: 20,
        clientName: "CBS",
        date: "December, 2015",
        description: "Promotional feature for the 2015 Kennedy Center Honors.",
        display: true,
        embed: "https://player.vimeo.com/video/306913526",
        slug: "kennedy-center-honors",
        thumbnail: "kennedycenterTHUMB-1400x800.jpg",
        title: "Kennedy Center Honors",
        type: "entertainment",
    },
    {
        id: 21,
        clientName: "CBS Watch!",
        date: "November, 2017",
        description: "Jane Pauley discusses the importance of failure in her career for CBS Watch! magazine.",
        display: true,
        embed: "https://player.vimeo.com/video/255457088",
        slug: "inspiredjanepauley",
        thumbnail: "CBSWatchJanePauleyInspire-1400x800.jpg",
        title: "Inspired by...",
        type: "entertainment",
    },
    {
        id: 22,
        clientName: "CBS Media Group",
        date: "May, 2017",
        description: "Sizzle reel covering 15 plus years of behind the scenes features of CBS programming.",
        display: false,
        embed: "https://player.vimeo.com/video/254903784",
        slug: "cbs-sizzle-reel",
        thumbnail: "CBSMEDIAGROUP-768x432.jpeg",
        title: "CBS SIZZLE REEL",
        type: "corporate",
    },
    {
        id: 23,
        clientName: "Creative America/CBS",
        date: "December, 2011",
        description: "Promotional video for Creative America as a part of their anti-piracy campaign.",
        display: false,
        embed: "https://player.vimeo.com/video/257555079",
        slug: "content-theft",
        thumbnail: "CreativeAmericaHomepageLogo-768x432.jpeg",
        title: "Content Theft",
        type: "corporate",
    },
    {
        id: 24,
        clientName: "",
        date: "",
        description: "A short documentary film on World War II Staff Sergeant John Simonetti.",
        display: false,
        embed: "https://player.vimeo.com/video/259055497",
        slug: "portrait-john-simonetti",
        thumbnail: "John-Simonetti-768x432.jpeg",
        title: "John Simonetti",
        type: "documentary",
    },
    {
        id: 25,
        clientName: "Northern Light Learning Center",
        date: "May, 2017",
        description: `Short documentary on Northern Light Learning Center's "Kid's Market Day". An event where homeschooled kids participate in a market where all the goods are produced and sold by children.`,
        display: false,
        embed: "https://player.vimeo.com/video/258108055",
        slug: "kids-market-day",
        thumbnail: "NLLC_MarketDay-768x432.jpeg",
        title: "Kid's Market Day",
        type: "documentary",
    },
    {
        id: 26,
        clientName: "CBS Watch!",
        date: "December, 2017",
        description: "Rose Leslie takes us behind the scenes of her photo shoot for CBS Watch! magazine.",
        display: false,
        embed: "https://player.vimeo.com/video/255454514",
        slug: "rose-leslie-behind-the-scenes",
        thumbnail: "WATCH_MAG-ROSE_LESLIE_2-copy-768x576.jpeg",
        title: "Rose Leslie: BTS",
        type: "entertainment",
    },
];
